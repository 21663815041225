import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PageSection = ({
  className,
  center,
  invert,
  image,
  steps,
  slide,
  icon,
  grid,
  top,
  id,
  style,
  children,
}) => (
  <>
    {id !== false && (
      <a className="anchor" id={id} /> // eslint-disable-line
    )}
    <div
      style={style}
      className={classNames('content-section', className || null, {
        center,
        invert,
        image,
        steps,
        slide,
        icon,
        grid,
        top,
      })}
    >
      {children}
    </div>
  </>
);

PageSection.defaultProps = {
  className: false,
  center: false,
  invert: false,
  image: false,
  steps: false,
  slide: false,
  icon: false,
  grid: false,
  id: false,
  top: false,
  style: {},
};

PageSection.propTypes = {
  center: PropTypes.bool,
  invert: PropTypes.bool,
  image: PropTypes.bool,
  steps: PropTypes.bool,
  icon: PropTypes.bool,
  slide: PropTypes.bool,
  grid: PropTypes.bool,
  top: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  style: PropTypes.object, // eslint-disable-line
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]).isRequired,
};

export default PageSection;
