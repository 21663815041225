/* eslint-disable max-len,jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Layout, { Seo } from '../components/Layout';
import Cover from '../components/Elements/Cover';
import PageSection from '../components/Elements/PageSection';
import withTranslations from '../i18n/withTranslations';

const Page = () => {
  const { t } = useTranslation('algemeneVoorwaarden-2.2');

  return (
    <Layout>
      <Seo title={t('seoTitle')} />
      <Cover>
        <PageSection center>
          <h1>{t('title')}</h1>
          <p>
            <Trans t={t} i18nKey="intro.download">
              <a href="/files/algemene-voorwaarden.pdf" target="_blank" />
            </Trans>
          </p>
          <p>{t('intro.description')}</p>

          <h4>{t('article.1.title')}</h4>
          <p>
            {t('article.1.intro')}
            <br />
            {t('article.1.1')}
            <br />
            {t('article.1.2')}
            <br />
            {t('article.1.3')}
            <br />
            {t('article.1.4')}
            <br />
            {t('article.1.5')}
            <br />
            {t('article.1.6')}
            <br />
            {t('article.1.7')}
            <br />
            {t('article.1.8')}
            <br />
            {t('article.1.9')}
            <br />
            {t('article.1.10')}
            <br />
            {t('article.1.11')}
          </p>

          <h4>{t('article.2.title')}</h4>
          <p>
            {t('article.2.1')}
            <br />
            {t('article.2.2')}
            <br />
            {t('article.2.3')}
            <br />
            {t('article.2.4')}
            <br />
            {t('article.2.5')}
          </p>

          <h4>{t('article.3.title')}</h4>
          <p>
            {t('article.3.1')}
            <br />
            {t('article.3.2')}
            <br />
            {t('article.3.3.intro')}
            <br />
            &bull;&nbsp;
            {t('article.3.3.a')}
            <br />
            &bull;&nbsp;
            {t('article.3.3.b')}
            <br />
            &bull;&nbsp;
            {t('article.3.3.c')}
            <br />
            {t('article.3.4')}
          </p>

          <h4>{t('article.4.title')}</h4>
          <p>
            {t('article.4.1')}
            <br />
            {t('article.4.2')}
            <br />
            {t('article.4.3')}
            <br />
            {t('article.4.4')}
            <br />
            {t('article.4.5')}
            <br />
            {t('article.4.6')}
          </p>

          <h4>{t('article.5.title')}</h4>
          <p>
            {t('article.5.1')}
            <br />
            {t('article.5.2')}
            <br />
            {t('article.5.3')}
          </p>

          <h4>{t('article.6.title')}</h4>
          <p>
            {t('article.6.1')}
            <br />
            {t('article.6.2.intro')}
            <br />
            &bull;&nbsp;
            {t('article.6.2.a')}
            <br />
            &bull;&nbsp;
            {t('article.6.2.b')}
            <br />
            &bull;&nbsp;
            {t('article.6.2.c')}
            <br />
            &bull;&nbsp;
            {t('article.6.2.d')}
            <br />
            &bull;&nbsp;
            {t('article.6.2.e')}
            <br />
            &bull;&nbsp;
            {t('article.6.2.f')}
            <br />
            &bull;&nbsp;
            {t('article.6.2.g')}
            <br />
            &bull;&nbsp;
            {t('article.6.2.h')}
            <br />
            &bull;&nbsp;
            {t('article.6.2.i')}
            <br />
            {t('article.6.3')}
            <br />
            {t('article.6.4')}
            <br />
            {t('article.6.5')}
            <br />
            {t('article.6.6')}
            <br />
            {t('article.6.7')}
            <br />
            {t('article.6.8')}
            <br />
            {t('article.6.9')}
          </p>

          <h4>{t('article.7.title')}</h4>
          <p>
            {t('article.7.1')}
            <br />
            {t('article.7.2')}
            <br />
            {t('article.7.3')}
            <br />
            {t('article.7.4')}
            <br />
            {t('article.7.5.intro')}
            <br />
            &bull;&nbsp;
            {t('article.7.5.a')}
            <br />
            &bull;&nbsp;
            {t('article.7.5.b')}
            <br />
            {t('article.7.6')}
            <br />
            {t('article.7.7')}
          </p>

          <h4>{t('article.8.title')}</h4>
          <p>
            {t('article.8.1')}
            <br />
            {t('article.8.2')}
            <br />
            {t('article.8.3')}
            <br />
            {t('article.8.4')}
            <br />
            {t('article.8.5')}
            <br />
            {t('article.8.6')}
            <br />
            {t('article.8.7')}
            <br />
            {t('article.8.8')}
            <br />
            {t('article.8.9')}
          </p>

          <h4>{t('article.9.title')}</h4>
          <p>
            {t('article.9.1')}
            <br />
            {t('article.9.2.intro')}
            <br />
            &bull;&nbsp;
            {t('article.9.2.a')}
            <br />
            &bull;&nbsp;
            {t('article.9.2.b')}
            <br />
            {t('article.9.3')}
            <br />
            {t('article.9.4')}
            <br />
            {t('article.9.5')}
          </p>

          <h4>{t('article.10.title')}</h4>
          <p>
            {t('article.10.1')}
            <br />
            {t('article.10.2')}
            <br />
            {t('article.10.3')}
          </p>

          <h4>{t('article.11.title')}</h4>
          <p>
            {t('article.11.1')}
            <br />
            {t('article.11.2')}
            <br />
            {t('article.11.3')}
            <br />
            {t('article.11.4.intro')}
            <br />
            &bull;&nbsp;
            {t('article.11.4.a')}
            <br />
            &bull;&nbsp;
            {t('article.11.4.b')}
            <br />
          </p>

          <h4>{t('article.12.title')}</h4>
          <p>
            {t('article.12.1')}
            <br />
            {t('article.12.2')}
          </p>

          <h4>{t('article.13.title')}</h4>
          <p>
            {t('article.13.1')}
            <br />
            {t('article.13.2')}
          </p>

          <h4>{t('article.14.title')}</h4>
          <p>
            {t('article.14.1')}
            <br />
            {t('article.14.2')}
            <br />
            {t('article.14.3')}
            <br />
            {t('article.14.4')}
            <br />
            {t('article.14.5')}
          </p>

          <h4>{t('article.15.title')}</h4>
          <p>
            {t('article.15.1')}
            <br />
            {t('article.15.2')}
            <br />
            {t('article.15.3')}
            <br />
            {t('article.15.4')}
          </p>

          <h4>{t('article.16.title')}</h4>
          <p>
            {t('article.16.1')}
            <br />
            {t('article.16.2')}
            <br />
            {t('article.16.3')}
            <br />
            {t('article.16.4')}
            <br />
            {t('article.16.5')}
            <br />
            {t('article.16.6')}
            <br />
            {t('article.16.7')}
          </p>

          <h4>{t('article.17.title')}</h4>
          <p>
            {t('article.17.1')}
            <br />
            {t('article.17.2')}
            <br />
            {t('article.17.3')}
            <br />
            {t('article.17.4')}
            <br />
            {t('article.17.5')}
            <br />
            {t('article.17.6')}
          </p>

          <h4>{t('article.18.title')}</h4>
          <p>
            {t('article.18.1')}
            <br />
            {t('article.18.2.intro')}
            <br />
            &bull;&nbsp;
            {t('article.18.2.a')}
            <br />
            &bull;&nbsp;
            {t('article.18.2.b')}
            <br />
            &bull;&nbsp;
            {t('article.18.2.c')}
            <br />
            &bull;&nbsp;
            {t('article.18.2.d')}
            <br />
            &bull;&nbsp;
            {t('article.18.2.e')}
            <br />
            &bull;&nbsp;
            {t('article.18.2.f')}
            <br />
            &bull;&nbsp;
            {t('article.18.2.g')}
            <br />
            &bull;&nbsp;
            {t('article.18.2.h')}
            <br />
            &bull;&nbsp;
            {t('article.18.2.i')}
            <br />
            &bull;&nbsp;
            {t('article.18.2.j')}
            <br />
            &bull;&nbsp;
            {t('article.18.2.k')}
            <br />
            {t('article.18.3')}
            <br />
            {t('article.18.4')}
          </p>

          <h4>{t('article.19.title')}</h4>
          <p>
            {t('article.19.1')}
            <br />
            {t('article.19.2')}
          </p>

          <h4>{t('article.20.title')}</h4>
          <p>
            {t('article.20.1')}
            <br />
            {t('article.20.2')}
            <br />
            {t('article.20.3')}
            <br />
            {t('article.20.4')}
          </p>

          <h4>{t('article.21.title')}</h4>
          <p>
            {t('article.21.1')}
            <br />
            {t('article.21.2')}
            <br />
            {t('article.21.3.intro')}
            <br />
            &bull;&nbsp;
            {t('article.21.3.a')}
            <br />
            &bull;&nbsp;
            {t('article.21.3.b')}
            <br />
            &bull;&nbsp;
            {t('article.21.3.c')}
            <br />
            &bull;&nbsp;
            {t('article.21.3.d')}
            <br />
            {t('article.21.4')}
            <br />
            {t('article.21.5')}
            <br />
            {t('article.21.6')}
            <br />
            {t('article.21.7')}
          </p>

          <h4>{t('article.22.title')}</h4>
          <p>
            {t('article.22.1')}
            <br />
            {t('article.22.2')}
            <br />
            {t('article.22.3')}
          </p>

          <h4>{t('article.23.title')}</h4>
          <p>{t('article.23.1')}</p>

          <h4>{t('article.24.title')}</h4>
          <p>
            {t('article.24.1')}
            <br />
            {t('article.24.2')}
            <br />
            {t('article.24.3')}
          </p>

          <h4>{t('article.25.title')}</h4>
          <p>
            {t('article.25.1')}
            <br />
            {t('article.25.2')}
          </p>

          <h4>{t('article.26.title')}</h4>
          <p>
            {t('article.26.1')}
            <br />
            {t('article.26.2')}
            <br />
            {t('article.26.3')}
            <br />
            {t('article.26.4')}
            <br />
            {t('article.26.5')}
            <br />
            {t('article.26.6')}
          </p>

          <p>{t('version')}</p>
        </PageSection>
      </Cover>
    </Layout>
  );
};

export default withTranslations(Page);
