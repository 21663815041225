import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';

import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';

const Cover = ({ nowrap, children }) => {
  // Create images.
  const data = useStaticQuery(graphql`
    fragment desktopImage on File {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          breakpoints: [2560]
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    fragment mobileImage on File {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          breakpoints: [1000]
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    query {
      darkAvocado: file(relativePath: { eq: "covers/dark-avocado.png" }) {
        ...desktopImage
      }
      darkAvocadoMobile: file(relativePath: { eq: "covers/dark-avocado.png" }) {
        ...mobileImage
      }
      lightAvocado: file(relativePath: { eq: "covers/light-avocado.png" }) {
        ...desktopImage
      }
      lightAvocadoMobile: file(relativePath: { eq: "covers/light-avocado-sm.png" }) {
        ...mobileImage
      }
    }
  `);

  const light = withArtDirection(getImage(data.lightAvocado), [
    {
      media: '(max-width: 768px)',
      image: getImage(data.lightAvocadoMobile),
    },
  ]);

  const dark = withArtDirection(getImage(data.darkAvocado), [
    {
      media: '(max-width: 768px)',
      image: getImage(data.darkAvocadoMobile),
    },
  ]);

  return (
    <div className="cover">
      <GatsbyImage
        image={light}
        style={{ position: 'fixed' }}
        className="img-light cover-image"
        alt=""
      />
      <GatsbyImage
        image={dark}
        style={{ position: 'fixed' }}
        className="img-dark cover-image"
        alt=""
      />
      {nowrap ? children : <div className="wrapped">{children}</div>}
    </div>
  );
};

Cover.defaultProps = {
  children: null,
  nowrap: false,
};

Cover.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  nowrap: PropTypes.bool,
};

export default Cover;
